<template>
    <setting-layout :title="$t('pages.user_tips.title', {name: userName}) | capitalize">
        <v-container>
            <v-row>
                <v-card-text>{{$t('pages.user_tips.subtitle', {name: userName})}}</v-card-text>
            </v-row>
            <v-row v-for="(tip, index) in tips"
                   :key="index"
                   @click="buyTipDialog=true;activeTip=tip"
                   style="cursor: pointer"
                   class="align-center"
            >
                <v-col cols="7">
                    <text-field disabled outlined v-model="tip.name" placeholder="Enter name"/>
                </v-col>
                <v-col cols="5">
                    <div v-if="tip.price == 0">
                        <text-field disabled outlined v-model="valueTipsZeroPrice"/>
                    </div>
                    <text-field v-else disabled outlined v-model="tip.price" prefix="$" style="font-size: 23px;" placeholder="0"/>
                </v-col>
            </v-row>
        </v-container>
        <yes-no-dialog v-if="buyTipDialog" @yes="handleConfirmPurchase" @no="buyTipDialog=false">
            <span v-if="activeTip.price == 0">
                {{ $t('pages.user_tips.zero_price_text', { item_name: activeTip.name }) }}
            </span>
            <span v-else>
                {{ $t('pages.user_tips.buy_item_text', { tip_name: activeTip.name, tip_price: activeTip.price }) }}
            </span>

        </yes-no-dialog>
        <insufficient-funds-dialog
            v-if="insufficientFundsDialog"
            @close="insufficientFundsDialog=false"
        />
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import {mapActions, mapMutations} from "vuex";
import capitalize from "lodash/capitalize"
import YesNoDialog from "@/components/app/setting/YesNoDialog.vue";
import {INSUFFICIENT_FUNDS_ERROR} from "@/configs/constants";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog.vue";

export default {
    name: "TipsSetting",
    components: {InsufficientFundsDialog, YesNoDialog, TextField, SettingLayout},
    filters: {
        capitalize,
    },
    data: () => ({
        tips: [],
        currentUser: null,
        activeTip: null,
        buyTipDialog: false,
        insufficientFundsDialog: false
    }),
    computed: {
        title() {
            return this.userName ? this.userName + ' Tips' : ''
        },
        userName() {
            return this.currentUser ? this.currentUser.name : null
        },
        userId() {
            return this.$route.params.id
        },
        valueTipsZeroPrice() {
            return this.$t('pages.user.setting.creator.tip_menu.on_request')
        }
    },
    methods: {
        ...mapMutations([
            'showSnackBar',
        ]),
        ...mapActions([
            'getTipsMenu',
        ]),
        handleConfirmPurchase() {
            this.buyTip()
        },
        fetchTips() {
            axios.get(`/api/users/${this.userId}/tips`)
                .then(({data: response}) => {
                    this.tips = response.data
                })
                .catch(() => this.showSnackBar(this.$t('messages.something_went_wrong')))
        },
        fetchUser() {
            axios.get(`/api/users/${this.userId}`)
                .then(({data}) => this.currentUser = data)
                .catch(() => this.showSnackBar(this.$t('messages.something_went_wrong')))
        },
        buyTip() {
            axios.post('api/tips/buy', {'tip_id': this.activeTip.id})
                .then(() => {
                    this.$router.push({
                        name: 'user.own.chat',
                        params: {id: this.userId},
                    })
                })
                .catch(({response}) => {
                    const {status, data} = response
                    if (status === 400 && data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                        this.buyTipDialog = false
                        this.insufficientFundsDialog = true
                        return;
                    }
                    this.showSnackBar(this.$t('messages.something_went_wrong'))
                })
        }
    },
    created() {
        this.fetchUser()
        this.fetchTips()
    },
}
</script>

<style scoped>

</style>
